// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter_component_filterButtonContainer__JAu0a {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 60px;\n}\n\n.filter_component_filterButton__-3VKG {\n  padding: 10px 10px;\n  cursor: pointer;\n  font-size: 16px;\n  top: 0px;\n  width: 90%;\n}\n.filter_component_filterButton__-3VKG:hover {\n  background: #e7ebf1;\n}", "",{"version":3,"sources":["webpack://./src/components/FiltersButton/FilterComponent/filter_component.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAFF;;AAKA;EACE,kBAAA;EACA,eAAA;EAEA,eAAA;EAEA,QAAA;EACA,UAAA;AAJF;AAME;EACE,mBAAA;AAJJ","sourcesContent":["@import '../../../scss/mixin.scss';\n\n\n.filterButtonContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 60px;\n}\n\n.filterButton {\n  padding: 10px 10px;\n  cursor: pointer;\n  //margin: 0 0;\n  font-size: 16px;\n  //magrin-bottom: 16px;\n  top:0px;\n  width: 90%;\n\n  &:hover {\n    background: #e7ebf1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterButtonContainer": "filter_component_filterButtonContainer__JAu0a",
	"filterButton": "filter_component_filterButton__-3VKG"
};
export default ___CSS_LOADER_EXPORT___;
