import { IconSvg } from 'components/common/IconSvg/IconSvg'
import { FC } from 'react'
import { pathT } from 'types/commonComponentsTypes'
import styles from './waterProgress.module.scss'

const waterBackgroundPath: pathT[] = [
  {
    d: 'M163.201 1.49869C206.368 -4.23801 251.814 6.77278 284.286 29.7589C318.223 53.7824 337.53 88.7099 336.989 124.52C336.454 159.947 317.719 195.856 281.457 217.067C248.476 236.36 204.375 226.553 163.201 226.177C122.861 225.81 79.1448 234.684 47.6695 214.935C14.0013 193.81 -5.08828 157.933 1.18497 124.52C6.96663 93.725 48.7264 78.9446 77.2707 57.2705C105.035 36.1889 125.063 6.56717 163.201 1.49869Z',
    fill: 'url(#paint0_linear_11130_60431)',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  },
]

interface IWaterLine {
  style: React.CSSProperties
}

const FirstWaterLinePath: FC<IWaterLine> = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161"
      height={12}
      style={style}
      viewBox={`0 0 161 12`}
      fill="none"
      preserveAspectRatio="none"
      shapeRendering="auto"
    >
      <g className={styles.waterWave}>
        <path
          d="M-97.5 1.1876C-89.8568 1.30821 -82.0094 1.47738 -73.7997 1.6907C-60.0632 2.04763 -46.3268 2.40456 -32.5902 2.48498C-19.9872 2.55877 -5.62402 2.20101 11.3207 1.77896C32.6937 1.2466 58.174 0.611948 89.4098 0.611948C111.717 0.611948 130.85 1.04354 149.275 1.45914C177.105 2.0869 203.318 2.67819 236.41 1.6907C291.41 0.0494442 325.91 0.0947678 371.41 1.88969C416.91 3.68461 453.985 3.34934 484.249 2.53253C487.181 2.45342 490.082 2.37051 492.963 2.2882C504.45 1.95997 515.606 1.64117 526.999 1.61007C542.968 1.56026 558.937 2.01288 574.906 2.46551C587.438 2.82069 599.969 3.17588 612.5 3.28827C628.349 3.43042 644.197 3.18076 660.046 2.9311C672.697 2.73182 685.349 2.53253 698 2.53253C711.165 2.53253 724.33 2.74833 737.495 2.96413C752.83 3.21551 768.165 3.46688 783.5 3.37718C797.236 3.29683 810.973 2.94023 824.709 2.58362C839.473 2.20035 854.236 1.81708 869 1.77678C890.8 1.70877 909.622 2.08082 928.399 2.45196C951.488 2.90834 974.507 3.36335 1002.91 2.99708C1018.26 2.79915 1032.93 2.47927 1047.41 2.16365C1081.95 1.41076 1115.38 0.682089 1154.2 1.6907C1167.94 2.04763 1181.67 2.40456 1195.41 2.48498C1210.74 2.57476 1226.08 2.32316 1241.41 2.07156C1269.01 1.61888 1291.21 1.60254 1318.86 2.0385C1334.71 2.28838 1350.56 2.53827 1366.41 2.39599C1378.94 2.2835 1391.47 1.92799 1404 1.57249C1438.44 0.595513 1460.91 0.592264 1494.66 1.63957C1497.77 1.736 1500.99 1.84382 1504.29 1.95388C1516.1 2.3485 1528.77 2.77192 1539.91 2.80236C1624.49 2.80236 1624.46 2.9535 1623.26 10.1713C1623.09 11.1462 1622.91 12.2501 1622.91 13.5L-97.5 13.5V1.1876Z"
          fill="url(#paint0_linear_16602_38652)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_16602_38652" x1="763" y1="0.5" x2="763" y2="13.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CFE2FF" />
          <stop offset="1" stopColor="#BBCEEB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

const SecondWaterLinePath: FC<IWaterLine> = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161"
      height="12"
      style={style}
      viewBox="0 0 161 12"
      fill="none"
      preserveAspectRatio="none"
      shapeRendering="auto"
    >
      <g className={styles.waterWave}>
        <path
          d="M-54.5 1.1876C-46.8568 1.30821 -39.0094 1.47738 -30.7997 1.6907C-17.0632 2.04763 -3.32678 2.40456 10.4098 2.48498C23.0128 2.55877 37.376 2.20101 54.3207 1.77896C75.6937 1.2466 101.174 0.611948 132.41 0.611948C154.717 0.611948 173.85 1.04354 192.275 1.45914C220.105 2.0869 246.318 2.67819 279.41 1.6907C334.41 0.0494442 368.91 0.0947678 414.41 1.88969C459.91 3.68461 496.985 3.34934 527.249 2.53253C530.181 2.45342 533.082 2.37051 535.963 2.2882C547.45 1.95997 558.606 1.64117 569.999 1.61007C585.968 1.56026 601.937 2.01288 617.906 2.46551C630.438 2.82069 642.969 3.17588 655.5 3.28827C671.349 3.43042 687.197 3.18076 703.046 2.9311C715.697 2.73182 728.349 2.53253 741 2.53253C754.165 2.53253 767.33 2.74833 780.495 2.96413C795.83 3.21551 811.165 3.46688 826.5 3.37718C840.236 3.29683 853.973 2.94023 867.709 2.58362C882.473 2.20035 897.236 1.81708 912 1.77678C933.8 1.70877 952.622 2.08082 971.399 2.45196C994.488 2.90834 1017.51 3.36335 1045.91 2.99708C1061.26 2.79915 1075.93 2.47927 1090.41 2.16365C1124.95 1.41076 1158.38 0.682089 1197.2 1.6907C1210.94 2.04763 1224.67 2.40456 1238.41 2.48498C1253.74 2.57476 1269.08 2.32316 1284.41 2.07156C1312.01 1.61888 1334.21 1.60254 1361.86 2.0385C1377.71 2.28838 1393.56 2.53827 1409.41 2.39599C1421.94 2.2835 1434.47 1.92799 1447 1.57249C1481.44 0.595513 1503.91 0.592264 1537.66 1.63957C1540.77 1.736 1543.99 1.84382 1547.29 1.95388C1559.1 2.3485 1571.77 2.77192 1582.91 2.80236C1667.49 2.80236 1667.46 2.9535 1666.26 10.1713C1666.09 11.1462 1665.91 12.2501 1665.91 13.5L-54.5 13.5V1.1876Z"
          fill="url(#paint0_linear_16602_38651)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_16602_38651" x1="806" y1="0.5" x2="806" y2="13.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CFE2FF" />
          <stop offset="1" stopColor="#BBCEEB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

const ThirdWaterLinePath: FC<IWaterLine> = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161"
      height="12"
      style={style}
      viewBox="0 0 161 12"
      fill="none"
      preserveAspectRatio="none"
      shapeRendering="auto"
    >
      <g className={styles.waterWave}>
        <path
          d="M359.5 1.1876C351.857 1.30821 344.009 1.47738 335.8 1.6907C322.063 2.04763 308.327 2.40456 294.59 2.48498C281.987 2.55877 267.624 2.20101 250.679 1.77896C229.306 1.2466 203.826 0.611948 172.59 0.611948C150.283 0.611948 131.15 1.04354 112.725 1.45914C84.8953 2.0869 58.6818 2.67819 25.5902 1.6907C-29.4098 0.0494442 -63.9098 0.0947678 -109.41 1.88969C-154.91 3.68461 -191.985 3.34934 -222.249 2.53253C-225.181 2.45342 -228.082 2.37051 -230.963 2.2882C-242.45 1.95997 -253.606 1.64117 -264.999 1.61007C-280.968 1.56026 -296.937 2.01288 -312.906 2.46551C-325.438 2.82069 -337.969 3.17588 -350.5 3.28827C-366.349 3.43042 -382.197 3.18076 -398.046 2.9311C-410.697 2.73182 -423.349 2.53253 -436 2.53253C-449.165 2.53253 -462.33 2.74833 -475.495 2.96413C-490.83 3.21551 -506.165 3.46688 -521.5 3.37718C-535.236 3.29683 -548.973 2.94023 -562.709 2.58362C-577.473 2.20035 -592.236 1.81708 -607 1.77678C-628.8 1.70877 -647.622 2.08082 -666.399 2.45196C-689.488 2.90834 -712.507 3.36335 -740.909 2.99708C-756.259 2.79915 -770.934 2.47927 -785.413 2.16365C-819.954 1.41076 -853.383 0.682089 -892.2 1.6907C-905.937 2.04763 -919.673 2.40456 -933.41 2.48498C-948.745 2.57476 -964.08 2.32316 -979.415 2.07156C-1007.01 1.61888 -1029.21 1.60254 -1056.86 2.0385C-1072.71 2.28838 -1088.56 2.53827 -1104.41 2.39599C-1116.94 2.2835 -1129.47 1.92799 -1142 1.57249C-1176.44 0.595513 -1198.91 0.592264 -1232.66 1.63957C-1235.77 1.736 -1238.99 1.84382 -1242.29 1.95388C-1254.1 2.3485 -1266.77 2.77192 -1277.91 2.80236C-1362.49 2.80236 -1362.46 2.9535 -1361.26 10.1713C-1361.09 11.1462 -1360.91 12.2501 -1360.91 13.5L359.5 13.5V1.1876Z"
          fill="url(#paint0_linear_16602_38650)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_16602_38650" x1="-501" y1="0.5" x2="-501" y2="13.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CFE2FF" />
          <stop offset="1" stopColor="#BBCEEB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

const FourthWaterLinePath: FC<IWaterLine> = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161"
      height="12"
      style={style}
      viewBox="0 0 161 12"
      fill="none"
      preserveAspectRatio="none"
      shapeRendering="auto"
    >
      <g className={styles.waterWave}>
        <path
          d="M215.5 1.1876C207.857 1.30821 200.009 1.47738 191.8 1.6907C178.063 2.04763 164.327 2.40456 150.59 2.48498C137.987 2.55877 123.624 2.20101 106.679 1.77896C85.3063 1.2466 59.826 0.611948 28.5902 0.611948C6.28345 0.611948 -12.85 1.04354 -31.2749 1.45914C-59.1047 2.0869 -85.3182 2.67819 -118.41 1.6907C-173.41 0.0494442 -207.91 0.0947678 -253.41 1.88969C-298.91 3.68461 -335.985 3.34934 -366.249 2.53253C-369.181 2.45342 -372.082 2.37051 -374.963 2.2882C-386.45 1.95997 -397.606 1.64117 -408.999 1.61007C-424.968 1.56026 -440.937 2.01288 -456.906 2.46551C-469.438 2.82069 -481.969 3.17588 -494.5 3.28827C-510.349 3.43042 -526.197 3.18076 -542.046 2.9311C-554.697 2.73182 -567.349 2.53253 -580 2.53253C-593.165 2.53253 -606.33 2.74833 -619.495 2.96413C-634.83 3.21551 -650.165 3.46688 -665.5 3.37718C-679.236 3.29683 -692.973 2.94023 -706.709 2.58362C-721.473 2.20035 -736.236 1.81708 -751 1.77678C-772.8 1.70877 -791.622 2.08082 -810.399 2.45196C-833.488 2.90834 -856.507 3.36335 -884.909 2.99708C-900.259 2.79915 -914.934 2.47927 -929.413 2.16365C-963.954 1.41076 -997.383 0.682089 -1036.2 1.6907C-1049.94 2.04763 -1063.67 2.40456 -1077.41 2.48498C-1092.74 2.57476 -1108.08 2.32316 -1123.41 2.07156C-1151.01 1.61888 -1173.21 1.60254 -1200.86 2.0385C-1216.71 2.28838 -1232.56 2.53827 -1248.41 2.39599C-1260.94 2.2835 -1273.47 1.92799 -1286 1.57249C-1320.44 0.595513 -1342.91 0.592264 -1376.66 1.63957C-1379.77 1.736 -1382.99 1.84382 -1386.29 1.95388C-1398.1 2.3485 -1410.77 2.77192 -1421.91 2.80236C-1506.49 2.80236 -1506.46 2.9535 -1505.26 10.1713C-1505.09 11.1462 -1504.91 12.2501 -1504.91 13.5L215.5 13.5V1.1876Z"
          fill="url(#paint0_linear_16602_38649)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_16602_38649" x1="-645" y1="0.5" x2="-645" y2="13.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CFE2FF" />
          <stop offset="1" stopColor="#BBCEEB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

interface IWaterProgress {
  percentage: number
}

export const WaterProgress: FC<IWaterProgress> = ({ percentage }) => {
  return (
    <div className={styles.waterWrapper}>
      <IconSvg styles={{ zIndex: '2' }} width={337} height={229} viewBoxSize="0 0 337 229" path={waterBackgroundPath}>
        <defs>
          <linearGradient id="paint0_linear_11130_60431" x1="0" y1="114.5" x2="337" y2="114.5" gradientUnits="userSpaceOnUse">
            <stop offset="0.135" stopColor="#3170E7" />
            <stop offset="1" stopColor="#7A90F7" />
          </linearGradient>
        </defs>
      </IconSvg>
      <h2 className={percentage <= 60 ? styles.waterWrapper_percentageTextTo60 : styles.waterWrapper_percentageTextAfter60}>{percentage}%</h2>
      <div className={styles.waterWrapper_waterPlayground}>
        <FirstWaterLinePath style={{ position: 'absolute', bottom: 0, height: `${percentage}%`, width: '200px' }} />
        <SecondWaterLinePath style={{ position: 'absolute', bottom: 0, height: `${percentage}%`, width: '800px', left: '-300px' }} />
        <ThirdWaterLinePath style={{ position: 'absolute', bottom: 0, height: `${percentage}%`, width: '200px' }} />
        <FourthWaterLinePath style={{ position: 'absolute', bottom: 0, height: `${percentage}%`, width: '200px' }} />
      </div>
    </div>
  )
}
