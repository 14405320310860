// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".previewCodeBlock_block_code__BM8S7 {\n  width: 100%;\n  border-radius: 9px;\n  margin-top: 1em;\n  padding: 1em !important;\n}", "",{"version":3,"sources":["webpack://./src/components/blocks/codeBlock/previewCodeBlock.module.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;AAFF","sourcesContent":["@import '../../../scss/mixin.scss';\n@import '../../../scss/variable.scss';\n\n.block_code {\n  width: 100%;\n  border-radius: 9px;\n  margin-top: 1em;\n  padding: 1em !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block_code": "previewCodeBlock_block_code__BM8S7"
};
export default ___CSS_LOADER_EXPORT___;
