// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modules_block_btnWrapper__SS2rZ:hover .modules_block_btn__S8rTS {\n  z-index: 10;\n}", "",{"version":3,"sources":["webpack://./src/Pages/School/Navigations/CoursesCreating/RedactorCourse/Constructor/ModulesAndLessonsBlock/ModulesBlock/modules_block.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF","sourcesContent":["@import '../../../../../../../../scss/mixin.scss';\n@import 'src/scss/variable';\n.btnWrapper:hover .btn{\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnWrapper": "modules_block_btnWrapper__SS2rZ",
	"btn": "modules_block_btn__S8rTS"
};
export default ___CSS_LOADER_EXPORT___;
