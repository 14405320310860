// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinkVievBlock_wrapper__hIDhp {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n  padding: 20px;\n}\n\n.LinkVievBlock_text__uXSzE {\n  font-size: 18px;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/Pages/CourseCatalog/CoursePreview/Blocks/styles/LinkVievBlock.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,aAAA;AAFF;;AAKA;EACE,eAAA;EACA,iBAAA;AAFF","sourcesContent":["@import 'scss/mixin.scss';\n@import 'scss/variable.scss';\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n  padding: 20px;\n}\n\n.text {\n  font-size: 18px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "LinkVievBlock_wrapper__hIDhp",
	"text": "LinkVievBlock_text__uXSzE"
};
export default ___CSS_LOADER_EXPORT___;
