// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".trainingProgram_wrapper__SBhkZ {\n  position: relative;\n  width: 1040px;\n}\n.trainingProgram_wrapper__SBhkZ h2 {\n  text-align: center;\n  padding: 2rem 0 1rem;\n  color: #7730BD;\n}", "",{"version":3,"sources":["webpack://./src/Pages/School/Navigations/CoursesCreating/RedactorCourse/CoursePage/Blocks/styles/trainingProgram.module.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,aAAA;AAFF;AAIE;EACE,kBAAA;EACA,oBAAA;EACA,cAAA;AAFJ","sourcesContent":["@import 'scss/mixin.scss';\n@import 'scss/variable.scss';\n\n.wrapper {\n  position: relative;\n  width: 1040px;\n\n  h2 {\n    text-align: center;\n    padding: 2rem 0 1rem;\n    color: #7730BD;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "trainingProgram_wrapper__SBhkZ"
};
export default ___CSS_LOADER_EXPORT___;
