// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".studentTestBlock_wrapper__X8UOn {\n  width: 100%;\n  padding: 34px 42px 40px 42px;\n  background-color: #fff;\n}\n@media only screen and (min-width: 375px) and (max-width: 480px) {\n  .studentTestBlock_wrapper__X8UOn {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: row;\n    justify-content: space-evenly;\n    padding: 24px 5px 30px 5px;\n  }\n}\n@media only screen and (min-width: 320px) and (max-width: 375px) {\n  .studentTestBlock_wrapper__X8UOn {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: row;\n    justify-content: space-evenly;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/StudentCourse/StudentTestBlock/studentTestBlock.module.scss"],"names":[],"mappings":"AAGA;EACI,WAAA;EACA,4BAAA;EACA,sBAAA;AAFJ;AAGI;EAJJ;IAKQ,aAAA;IACA,eAAA;IACA,mBAAA;IACA,6BAAA;IACA,0BAAA;EAAN;AACF;AACM;EAXN;IAYQ,aAAA;IACA,eAAA;IACA,mBAAA;IACA,6BAAA;EAEN;AACF","sourcesContent":["@import '../../../scss/mixin.scss';\n@import '../../../scss/variable.scss';\n\n.wrapper {\n    width: 100%;\n    padding: 34px 42px 40px 42px;\n    background-color: #fff;\n    @media only screen and (min-width : 375px) and (max-width : 480px) {\n        display: flex;\n        flex-wrap: wrap;\n        flex-direction: row;\n        justify-content: space-evenly;\n        padding: 24px 5px 30px 5px;\n      }\n      @media only screen and (min-width : 320px) and (max-width : 375px) {\n        display: flex;\n        flex-wrap: wrap;\n        flex-direction: row;\n        justify-content: space-evenly;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "studentTestBlock_wrapper__X8UOn"
};
export default ___CSS_LOADER_EXPORT___;
